<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <base-material-card color="primary" light max-width="100%" width="100%" class="px-5 py-3">
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                {{ $t("Resetare parolă") }}
              </h1>
            </div>
          </template>
          <v-card-text class="text-center">
            <v-form ref="formChange" @submit.prevent>
              <v-text-field
                ref="password"
                v-model="password"
                color="primary"
                :label="$t('Password')"
                :rules="inputValidate"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append.prevent="show = !show"
                @keydown.enter="$refs.cpassword.focus()"
              />
              <v-text-field
                ref="cpassword"
                v-model="confirmPassword"
                color="primary"
                :label="$t('Confirmare parolă')"
                :rules="inputValidatePass"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append.prevent="show = !show"
                @keydown.enter="createAccount"
              />
              <v-btn color="primary" @click="createAccount">
                {{ $t("Resetează") }}
              </v-btn>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import md5 from "js-md5"
import { EventBus } from "../../../EventBus"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      show: false,
      password: "",
      confirmPassword: "",
      token: ""
    }
  },
  computed: {
    inputValidate() {
      const rules = []
      rules.push(v => !!v || this.$t("Câmpul este obligatoriu"))
      return rules
    },
    inputValidatePass() {
      const rules = []
      rules.push(v => !!v || this.$t("Câmpul este obligatoriu"))
      rules.push(v => v == this.password || this.$t("Parolele nu sunt identice"))
      return rules
    }
  },
  created() {
    this.$log("token ", this.$route.query.token)
    this.token = this.$route.query.token
  },
  methods: {
    createAccount() {
      this.$log("createAccount")
      if (this.$refs.formChange.validate()) {
        axios.post("reset_password/" + this.token + "/?password=" + md5(this.password)).then(response => {
          this.$log("reset response ", response)
          if (response.status == 200) {
            EventBus.$emit("show-alert", {
              message: response.data.msg,
              color: "info",
              timeout: 7000
            })
          }
          this.$router.push({ path: "/autentificare" })
        })
      }
    }
  }
}
</script>
<style></style>
